.home {

	&-header {
		text-align: left;

		$mobile-img-size: 468px;
		$mobile-img-inner-size: 376;

		@include mq($to: tablet-small) {
			background: url('/assets/img/page/home/header-mobile.png') 50% 0 no-repeat;
			background-size: 125% auto;
			text-align: center;
		}

		@include mq($from: $mobile-img-inner-size * 1px, $to: tablet-small) {
			background-size: $mobile-img-size auto;
		}

		@include mq(tablet-small) {
			background: url('/assets/img/page/home/header.png') -5vw 50% no-repeat;
			background-size: contain;
		}

		@include mq(tablet-wide) {
			background-position: 50% 50%;
		}

		.site-header__placeholder {
			display: block;

			@include mq($to: tablet-small) {
				@include responsive-ratio($mobile-img-inner-size, 382, true);
				max-width: rem($mobile-img-inner-size * 1px) - $page-gutter--phone-wide;
			}

			@include mq(tablet-small) {
				@include responsive-ratio(700, 1000, true);
				max-width: 700px;
			}
		}

		.site-header__content {

			h1 {
				@include fp(font-size, 56, 142, 640, 1488);
				line-height: .7;
				margin-bottom: .3em;
				white-space: nowrap;

				small {
					color: $c--tan-dark;
					font-size: .7em;
				}
			}

			@include mq(tablet-small) {

				p {
					@include push--sides(0);
					max-width: rem(600px);
					padding-left: 1%;
				}
			}
		}
	}
}