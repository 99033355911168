.block {
	position: relative;
	z-index: 1;
	padding: $spacing-unit--small;

	@include mq('tablet') {
		padding: $spacing-unit;
	}

	@include mq('desktop') {
		padding: $spacing-unit--large;
	}

	&--large-padding {
		@include mq('desktop-wide') {
			padding: $spacing-unit--x-large;
		}
	}

	&--cream {
		background: $c--cream;
	}

	&--secondary {
		background: $c--brand-secondary;
		color: $c--cream;
	}

	&--tan-dark {
		background-color: $c--tan-dark;
		color: $c--cream;

		.button:hover {
			color: $c--tan-dark;
		}
	}

	&--tan {
		background-color: $c--tan;
		color: $c--cream;

		.button:hover {
			color: $c--tan;
		}
	}

	// 370 height
	@each $size in 480 730 {
		&--img-#{$size} {

			.block__img {
				@include responsive-ratio($size, 228, true);
			}

			+ .block__push {
				@include responsive-ratio($size, 142, true);
			}

			@if $size == 480 {
				@include mq($to: tablet-wide) {
					.block__img,
					+ .block__push {
						@include push--auto;
						max-width: 76%;
					}
				}
			}
		}
	}

	// 414 height variant
	@each $size in 1240 {
		&--img-#{$size} {
			.block__img { @include responsive-ratio($size, 238, true); }
			+ .block__push { @include responsive-ratio($size, 176, true); }
		}
	}

	&__push {
		display: block;
	}

	&__img {
		$m: n($spacing-unit--small);

		margin: $rel-spacing-unit--large $m $m;
		position: relative;

		@include mq('tablet') {
			$m: n($spacing-unit);
			margin: $rel-spacing-unit--large $m $m;
		}

		@include mq('desktop') {
			$m: n($spacing-unit--large);
			margin: $rel-spacing-unit--large $m $m;
		}

		img {
			position: absolute;
			top: 0; right: 0; left: 0;
			width: 100%;
		}
	}
}