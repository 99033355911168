.contact-us-header {
	position: relative;
	background: #dcd1c1;

	&__map {
		display: block;

		@include mq($to: tablet) {
			background: 50% no-repeat;
			background-size: cover;
		}

		@include mq($to: phone-wide) {
			@include responsive-ratio(1, 1, true);
			background-image: url('/assets/img/page/contact/map-small.png');
		}

		@include mq($from: phone-wide, $to: tablet) {
			@include responsive-ratio(13, 8, true);
			background-image: url('/assets/img/page/contact/map-medium.png');
		}

		@include mq(tablet) {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			z-index: 0;
			background: url('/assets/img/page/contact/map.png') 50% 50% no-repeat;
			background-size: contain;
		}
	}

	@include mq(tablet) {
		@include soft--ends(10vh);
	}

	@include mq(desktop-wide) {
		@include soft--ends(15vh);
	}

	&__maps-link {

		@include mq(tablet) {
			position: absolute;
			right: $rel-spacing-unit; bottom: $rel-spacing-unit;
			z-index: 1;
			text-transform: uppercase;
		}
	}
}

.contact-us-form {
	fieldset {
		@include push--auto;
		max-width: rem(480px);
	}
}