// block decorations

@mixin bd($id, $w, $h, $min-scale: .3) {

	$min-width: $w * $min-scale;

	&--#{$id} {
		width: ( ( $w / 2000 ) * 100 ) * 1vw;
		min-width: $min-width * 1px;
		max-width: $w * 1px;
		background-image: url('/assets/img/decorations/#{$id}.png');

		&::before {
			@include responsive-ratio($w, $h, false);
		}
	}
}

.d {
	position: absolute;
	z-index: -1;
	pointer-events: none;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;

	&::before {
		content: '';
		display: block;
	}

	// left only
	@include bd(leaf-one, 150, 280);
	@include bd(leaf-two, 190, 340);
	@include bd(leaf-three, 146, 446);

	// right only
	@include bd(leaf-four, 120, 390);
	@include bd(star-anise, 110, 200);

	// corners only
	@include bd(peppercorns, 390, 390);
	@include bd(leaf-five, 400, 400);

	// flip the left / right
	&--flip-h { transform: scaleX(-1); }
	&--flip-v {
		transform: scaleY(-1);
		&.d--flip-h { transform: scale(-1); }
	}
}

// page decorations
.pd {

	&--l, &--r { transform: translateY(-50%); }

	&--r { right: 0; top: 50%; }
	&--l { top: 50%; left: 0; }

	&--rt { top: 5%; right: 0; }
	&--rb { right: 0; bottom: 0; }

	&--lt { top: 5%; left: 0; }
	&--lb { left: 0; bottom: 0; }
}

// block decorations
.bd {

	// middle sides
	&--t, &--b {
		transform: translateX(-50%);
		&.d--flip-v { transform: translateX(-50%) scaleY(-1); }
	}
	&--r, &--l {
		transform: translateY(-50%);
		&.d--flip-h { transform: translateY(-50%) scaleX(-1); }
	}

	&--t { bottom: 100%; left: 50%; }
	&--r { top: 50%; left: 100%; }
	&--b { top: 100%; left: 50%; }
	&--l { top: 50%; right: 100%; }

	// top / bottom / left / right sides
	&--tr { right: 0; bottom: 100%; }
	&--tl { bottom: 100%; left: 0; }

	&--rt { top: 0; left: 100%; }
	&--rb { bottom: 0; left: 100%; }

	&--br { top: 100%; right: 0; }
	&--bl { top: 100%; left: 0; }

	&--lt { top: 0; right: 100%; }
	&--lb { bottom: 0; right: 100%; }

	// corners
	// !! Uses rotation: asset should always be built for top right corner
	&--ctr {
		bottom: 100%; left: 100%;
		transform: translate(-50%, 50%);
	}

	&--cbr {
		top: 100%; left: 100%;
		transform: translate(-50%, -50%) rotate(90deg);
	}

	&--cbl {
		top: 100%; right: 100%;
		transform: translate(50%, -50%) rotate(180deg);
	}

	&--ctl {
		bottom: 100%; right: 100%;
		transform: translate(50%, 50%) rotate(-90deg);
	}
}
