.shop {
	@include soft--ends;
	position: relative;
	z-index: z('shop');
	background: $c--brand-secondary;
	color: $c--cream;
	text-align: right;

	.container {
		@include cf;
	}

	&__inner {

		@include mq($to: tablet) {
			text-align: center;
		}

		@include mq(tablet) {
			position: relative;
			float: right;
			padding-right: 30%;
		}

		h1 {
			@include heading;
			font-size: $h--large;

			@include mq(tablet-wide) {
				font-size: rem(66px);
			}

			@include mq(desktop) {
				font-size: rem(75px);
			}

			@include mq(desktop-wide) {
				font-size: $h--x-large;
			}
		}
	}

	&__box {

		@include mq($to: tablet) {
			display: none;
		}

		@include mq(tablet) {
			@include responsive-ratio(392, 324, true);
			position: absolute;
			top: 50%; right: 0;
			width: 25%;
			max-width: 392px;
			transform: translateY(-50%);

			img {
				position: absolute;
				top: 0; left: 0;
				width: 100%;
			}
		}
	}

	&__flowers {
		@include responsive-ratio(192,146,true);
		position: absolute;
		top: 100%; right: 100%;
		width: 30%;
		min-width: 100px;
		max-width: 192px;
		background: url('/assets/img/decorations/footer-flowers.png') 50% no-repeat;
		background-size: contain;
		pointer-events: none;
		transform: translate(60%, 15%);

		@include mq(phone-wide) {
			width: 20%;
			transform: translate(50%, -50%);
		}
	}
}