/*

	Box sizing

*/

* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}



/*

	Basic document styling

*/

html {
	@include antialias;
	font-size: $html-font-size;
	height: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: none;
}

body {
	height: 100%;
	background: $c--body-bg url('/assets/img/textures/background.png');
	background-size: 128px;
	color: $c--body-colour;
	font-family: $stack;
	font-size: $p--x-small;
	line-height: $lh;

	@include mq(tablet-wide) {
		font-size: $p--small;
	}

	@include mq(desktop-wide) {
		font-size: $p;
	}
}

.site-wrapper {
	min-height: 100%;
	padding-top: $nav-height;
	overflow: hidden;

	@include mq(tablet-small) {
		border: solid white;
		border-width: 0 $border $border;
	}

	@include mq(tablet-wide) {
		padding-top: $nav-height--tablet-wide;
		border-width: 0 $border--tablet-wide $border--tablet-wide;
	}

	@include mq(desktop) {
		padding-top: $nav-height--desktop;
		border-width: 0 $border--desktop $border--desktop;
	}
}



/*

	Text selection/highlighting

*/

@include selection {
	background: $c--brand-primary;
	color: $c--white;
	text-shadow: none;
}
