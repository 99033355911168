.back {
	@include push--auto;
	display: inline-block;
	border: solid 5px $c--brand-primary;
	border-image: url('/assets/img/textures/border-brown-light.png') 10 fill repeat;
	text-decoration: none;
	color: $c--brand-primary;

	&-wrapper {
		@include site-content-padding;
	}

	&__img {

		@include mq($to: tablet-small) {
			margin: -1em -1em -.5em;

			img {
				width: rem(280px);
			}
		}

		@include mq(tablet-small) {
			display: inline-block;
			width: 10.8em;
			margin: -1em -.5em -1.9em .5em;

			img {
				@include push--auto;
				display: block;
			}
		}
	}

	&__text {
		display: inline-block;
		padding: 1em;
	}
}