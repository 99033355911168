@import 'settings';
@import 'utility/functions';
@import '../variables.json';


/*

	Typography

*/

// font stacks
// for embedded custom fonts, list name below as normal. To generate code see example in utlity/core.scss

$stack: 'Merriweather', Georgia, serif;
$stack--heading: 'Microbrew', Impact, sans-serif;


// font sizes

$h--xxx-small: rem(16px);
$h--xx-small: rem(18px);
$h--x-small: rem(20px);
$h--small: rem(22px);
$h--medium: rem(26px);
$h--large: rem(50px);
$h--x-large: rem(92px);
$h--xx-large: rem(100px);
$h--xxx-large: rem(140px);

$p--xx-small: rem(13px);
$p--x-small: rem(16px);
$p--small: rem(18px);
$p: rem(20px);
$p--medium: rem(24px);
$p--large: rem(27px);

// line-height ratios

$lh: 1.8;
$lh--x-small: 1.1;
$lh--small: 1.25;
$lh--large: 2;


// letter spacing

$ls: (
	base: 0,
	large: 200
);

// weights

// $w--light: 300;
$w--normal: 400;
// $w--semibold: 600;
$w--bold: 700;
// $w--heavy: 800;



/*

	Layout

*/

// basic spacing units

$spacing-unit: rem(30px);
$spacing-unit--x-small: rem(10px);
$spacing-unit--small: rem(20px);
$spacing-unit--large: rem(40px);
$spacing-unit--x-large: rem(60px);
$spacing-unit--xx-large: rem(80px);

$rel-spacing-unit: 1em;
$rel-spacing-unit--small: .5em;
$rel-spacing-unit--large: 1.5em;

$nav-spacing-ends: $spacing-unit--x-small;
$nav-spacing-ends--desktop: h( $spacing-unit--x-small + $spacing-unit--small );
$nav-spacing-ends--desktop-wide: $spacing-unit--small;

$nav-spacing-sides: rem(15px);
$nav-spacing-sides--desktop: $spacing-unit--small;
$nav-spacing-sides--desktop-wide: 5%;


// page gutter

$page-gutter: $spacing-unit--x-small;
$page-gutter--phone: $spacing-unit--small;
$page-gutter--phone-wide: $spacing-unit;
$page-gutter--tablet: $spacing-unit--large;
$page-gutter--desktop: $spacing-unit--x-large;


// grid

$grid-column-count: 24;
$grid-column-gutter: $spacing-unit--small;
$grid-column-gutter--tablet: $spacing-unit;
$grid-column-gutter--desktop: $spacing-unit--large;


// dimensions

$nav-height: rem(62px);
$nav-height--tablet-wide: rem(90px);
$nav-height--desktop: rem(124px);

$border: rem(10px);
$border--tablet-wide: rem(15px);
$border--desktop: rem(30px);


// media queries and breakpoints
// build mobile first 300+

$breakpoints--rem: $breakpoints;

@each $name, $width in $breakpoints {
	$breakpoints--rem: map-merge($breakpoints--rem, ($name: rem($width * 1px)));
}

@each $name, $width in $breakpoints {
	$breakpoints: map-merge($breakpoints, ($name: em($width * 1px, $browser-default-font-size)));
}


// once dev mode is false, you must define any block grids, column grids and flush margins for each breakpoint
// this is to keep unused code to a minimum

$grid-bp: (
	'base': (
		'width': (20),
		'push': (2),
		'pull': (),
		'block': (),
		'flush': (1,2)
	),
	'phone': (
		'width': (),
		'push': (),
		'pull': (),
		'block': (),
		'flush': ()
	),
	'phone-wide': (
		'width': (20),
		'push': (2),
		'pull': (),
		'block': (),
		'flush': ()
	),
	'phablet': (
		'width': (),
		'push': (),
		'pull': (),
		'block': (),
		'flush': ()
	),
	'tablet-small': (
		'width': (10,12,14,20),
		'push': (0,2,12,14),
		'pull': (10,12),
		'block': (2),
		'flush': ()
	),
	'tablet': (
		'width': (6,8,12,16,18,20,22),
		'push': (0,1,2,3,10),
		'pull': (),
		'block': (),
		'flush': (2)
	),
	'tablet-wide': (
		'width': (8,10,11,12,16,20,22),
		'push': (1,2,4,16),
		'pull': (8,11),
		'block': (3),
		'flush': ()
	),
	'desktop': (
		'width': (4,5,12,20),
		'push': (2,3,6),
		'pull': (),
		'block': (),
		'flush': ()
	),
	'desktop-wide': (
		'width': (9,10),
		'push': (1,2,4),
		'pull': (),
		'block': (),
		'flush': ()
	)
);

$max-width: rem(1488px);


// z-indexing

/*
ordered from high to low
suggested naming convention would be the class/ID the z-index is going on
*/

$z-indexes: (
	'outdated-browser',
	'site-nav',
	'mobile-site-nav',
	'site-header',
	'site-content',
	'shop',
	'site-footer'
);



/*

	Colours

*/

// brand colours

$c--brand-primary: #a35a3e;
$c--brand-secondary: #7e9a93;

$c--teal: #025c5e;

$c--brown-light: #aa8c82;
$c--brown: #8d6c64;
$c--brown-dark: #3c342e;

$c--tan: #e2ac7a;
$c--tan-dark: #b69774;

$c--cream: #fff4e6;
$c--cream-dark: #e6d9c8;


// greyscale

$c--black: #1a1a1a;
$c--grey-dark: #444;
$c--grey: #888;
$c--grey-light: #ddd;
$c--grey-x-light: #fafafa;
$c--white: #fff;


// other colours

$c--red: #f50023;
$c--green: #24b35d;
$c--blue: #4D90FE;


// social colours

$c--twitter: #55acee;
$c--facebook: #3b5998;
$c--youtube: #bb0000;
$c--pinterest: #cb2027;
$c--linkedin: #007bb5;
$c--instagram: #8a3ab9;


// form & message positive/negative colours

$c--positive: $c--green;
$c--negative: $c--red;


// top level element colours

$c--body-bg: $c--cream-dark;
$c--body-colour: $c--brown;


// colour map (only use colours from the styleguide)
// used for when looping through panel/theme styles

$colours: (
	// primary colours
	'yellow': (
		'base': $c--brand-primary
	),
	'green': (
		'base': $c--green
	),
	'red': (
		'base': $c--red
	),
	'blue': (
		'base': $c--blue
	),
	// greyscale
	'black': (
		'base': $c--black
	),
	'grey': (
		'base': $c--grey
	),
	'grey-dark': (
		'base': $c--grey-dark
	),
	'grey-light': (
		'base': $c--grey-light
	),
	'grey-off-white': (
		'base': $c--grey-x-light
	),
	'white': (
		'base': $c--white
	)
);



/*

	Easing/Timing

*/

//	Cubic
$ease--in-cubic: cubic-bezier(.55,.055,.675,.19);
$ease--out-cubic: cubic-bezier(.215,.61,.355,1);
$ease--in-out-cubic: cubic-bezier(.645,.045,.355,1);

//	Circ
$ease--in-circ: cubic-bezier(.6,.04,.98,.335);
$ease--out-circ: cubic-bezier(.075,.82,.165,1);
$ease--in-out-circ: cubic-bezier(.785,.135,.15,.86);

//	Expo
$ease--in-expo: cubic-bezier(.95,.05,.795,.035);
$ease--out-expo: cubic-bezier(.19,1,.22,1);
$ease--in-out-expo: cubic-bezier(1,0,0,1);

//	Quad
$ease--in-quad: cubic-bezier(.55,.085,.68,.53);
$ease--out-quad: cubic-bezier(.25,.46,.450,.94);
$ease--in-out-quad: cubic-bezier(.455,.03,.515,.955);

//	Quart
$ease--in-quart: cubic-bezier(.895,.03,.685,.22);
$ease--out-quart: cubic-bezier(.165,.84,.44,1);
$ease--in-out-quart: cubic-bezier(.77,0,.175,1);

//	Quint
$ease--in-quint: cubic-bezier(.755,.05,.855,.06);
$ease--out-quint: cubic-bezier(.23,1,.320,1);
$ease--in-out-quint: cubic-bezier(.86,0,.07,1);

//	Sine
$ease--in-sine: cubic-bezier(.47,0,.745,.715);
$ease--out-sine: cubic-bezier(.39,.575,.565,1);
$ease--in-out-sine: cubic-bezier(.445,.05,.55,.95);

//	Back
$ease--in-back: cubic-bezier(.6,-.28,.735,.045);
$ease--out-back: cubic-bezier(.175,.885,.32,1.275);
$ease--in-out-back: cubic-bezier(.68,-0.55,.265,1.55);

//	Smooth fade
$ease--smooth-fade: cubic-bezier(.365,.005,.355,1);
