/*---

Unstyled list (default)

```
<ul>
	<li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus enim, fugiat a consectetur odit, nam ab, deserunt veniam autem quas animi laudantium! Dolorum animi quos dolorem nesciunt eius, velit obcaecati?</li>
	<li>Nemo consequuntur ad earum sequi, mollitia quas impedit, possimus blanditiis aspernatur alias eius eligendi, suscipit quos itaque voluptatem. Cum ullam cupiditate id beatae neque vel maxime architecto aspernatur, corrupti odio.</li>
	<li>Qui repellat omnis, tempore reiciendis. Distinctio, veritatis, atque? Dignissimos aut deleniti, maiores eum dolorum rerum, cum repudiandae sequi alias odio quaerat saepe deserunt nobis incidunt iure aliquid vero esse omnis.</li>
</ul>
```

Unordered list

```
<ul class="ul">
	<li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus enim, fugiat a consectetur odit, nam ab, deserunt veniam autem quas animi laudantium! Dolorum animi quos dolorem nesciunt eius, velit obcaecati?</li>
	<li>Nemo consequuntur ad earum sequi, mollitia quas impedit, possimus blanditiis aspernatur alias eius eligendi, suscipit quos itaque voluptatem. Cum ullam cupiditate id beatae neque vel maxime architecto aspernatur, corrupti odio.</li>
	<li>Qui repellat omnis, tempore reiciendis. Distinctio, veritatis, atque? Dignissimos aut deleniti, maiores eum dolorum rerum, cum repudiandae sequi alias odio quaerat saepe deserunt nobis incidunt iure aliquid vero esse omnis.</li>
</ul>
```

Ordered list

```
<ol class="ol">
	<li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus enim, fugiat a consectetur odit, nam ab, deserunt veniam autem quas animi laudantium! Dolorum animi quos dolorem nesciunt eius, velit obcaecati?</li>
	<li>Nemo consequuntur ad earum sequi, mollitia quas impedit, possimus blanditiis aspernatur alias eius eligendi, suscipit quos itaque voluptatem. Cum ullam cupiditate id beatae neque vel maxime architecto aspernatur, corrupti odio.</li>
	<li>Qui repellat omnis, tempore reiciendis. Distinctio, veritatis, atque? Dignissimos aut deleniti, maiores eum dolorum rerum, cum repudiandae sequi alias odio quaerat saepe deserunt nobis incidunt iure aliquid vero esse omnis.</li>
</ol>
```

Inline list

```
<ul class="inline-children--block">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
	<li>item 5</li>
</ul>
```

*/

.ol,
.ul,
.ul--unstyled {

	> li {
		margin-top: $rel-spacing-unit--small;

		&:first-child {
			margin-top: 0;
		}
	}
}

.ol {
	list-style-type: decimal;
	margin-left: $spacing-unit;
}

.ul {
	list-style-type: disc;
	margin-left: $spacing-unit;
}
