.container {
	@include push--sides($page-gutter);
	position: relative;
	max-width: $max-width;

	@include mq(phone) {
		@include push--sides($page-gutter--phone);
	}

	@include mq(phone-wide) {
		@include push--sides($page-gutter--phone-wide);
	}

	@include mq(tablet) {
		@include push--sides($page-gutter--tablet);
	}

	@include mq(desktop) {
		@include push--sides($page-gutter--desktop);
	}

	@include mq($max-width + ( $page-gutter--desktop * 2 )) {
		@include push--auto;
	}
}