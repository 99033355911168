.site-nav {
	@include unselectable;
	@include hardware;
	@include soft--sides($spacing-unit--x-small);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0; right: 0; left: 0;
	z-index: z('site-nav');
	height: $nav-height;
	background: $c--white;

	@include mq(phone) {
		@include soft--sides($spacing-unit--small);
	}

	@include mq(tablet-wide) {
		@include soft--sides($border--tablet-wide);
		height: $nav-height--tablet-wide;
	}

	@include mq(desktop) {
		@include soft--sides($border--desktop);
		height: $nav-height--desktop;
	}

	nav {
		@include mq($to: 'tablet-wide') {
			display: none;
		}

		@include mq(tablet-wide) {
			display: block;
			width: 40%;
			font-size: 0;
			white-space: nowrap;
		}
	}

	&__logo {
		display: block;
		position: relative;
		font-size: $p--x-small;

		@include mq(tablet-wide) {
			width: 20%;
		}

		// Compact logo
		@include mq($to: tablet) {

			a {
				@include responsive-ratio(225, 76, true);
				width: 40vw;
				max-width: rem(128px);
			}
		}

		@include mq(tablet) {

			a {
				@include responsive-ratio(260, 55, true);
				width: 20vw;
				min-width: rem(190px);
				max-width: rem(340px);
			}
		}

		@include mq($to: tablet) {
			width: 50%;
		}

		a {
			@include push--auto;
			display: block;
			width: 100%;
			position: relative;
		}

		img {
			position: absolute;
			top: 0; left: 0;
			height: 100%;
		}
	}

	&__company {
		text-align: right;
	}

	&__toggle {
		width: 20%;
		color: $c--brand-secondary;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(black, 0);

		$icon-size: rem(28px);

		span {
			@include hardware;
			display: block;
			position: relative;
			width: $icon-size;
			height: $icon-size;

			&::after {
				$size: 300%;
				$offset: hn($size - 100%);

				@include pseudo;
				visibility: hidden;
				top: $offset; left: $offset;
				z-index: 0;
				width: $size;
				height: $size;
				border-radius: 50%;
				background: rgba($c--brand-secondary, .4);
				transform: scale(0);
				transition: transform .3s $ease--out-expo, opacity .3s $ease--smooth-fade;
			}
		}

		svg {
			display: block;
			position: absolute;
			top: 0; left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			fill: currentColor;
			transition: transform .2s $ease--in-out-cubic, opacity .2s $ease--smooth-fade;

			&:last-child {
				transform: rotate(180deg);
				opacity: 0;
			}
		}

		&--open {

			span::after {
				visibility: visible;
				transform: scale(1);
				opacity: 0;
			}

			svg:first-child {
				opacity: 0;
				transform: rotate(-180deg);
			}

			svg:last-child {
				opacity: 1;
				transform: rotate(0deg);
			}
		}

		@include mq(tablet-wide) {
			display: none;
		}
	}

	&__shop {
		width: 20%;
		text-align: right;

		a {
			color: $c--brand-secondary;
		}

		@include mq(tablet-wide) {
			display: none;
		}
	}

	nav {

		a {
			@include soft--ends($nav-spacing-ends);
			display: inline-block;
			position: relative;
			text-transform: uppercase;
			text-decoration: none;
			font-size: $p--xx-small;
			letter-spacing: ls('large');
			color: $c--brown-dark;
			transition: color .2s $ease--smooth-fade;

			@include mq(tablet-wide) {
				@include push--sides($nav-spacing-sides);
			}

			@include mq(desktop) {
				@include push--sides($nav-spacing-sides--desktop);
				@include soft--ends($nav-spacing-ends--desktop);
			}

			@include mq(desktop-wide) {
				@include soft--ends($nav-spacing-ends--desktop-wide);
				@include push--sides($nav-spacing-sides--desktop-wide);
				font-size: $p--x-small;
			}

			&::after {
				@include pseudo;
				bottom: 15%; right: 0; left: 0;
				height: 2px;
				background: $c--brand-secondary;
				opacity: 0;
				transform: scaleX(0);
				transition: opacity .2s $ease--smooth-fade, transform .2s $ease--out-cubic;
			}

			&:hover,
			&.is-active {
				color: $c--brand-secondary;

				&::after {
					opacity: .5;
					transform: scaleX(1);
				}
			}

			&.highlight {
				margin: 0;
				background: $c--brand-secondary;
				transition: background-color .2s $ease--smooth-fade;

				&::after { content: none; }

				@include mq(tablet-wide) {
					@include soft--sides($spacing-unit--small);
					margin-left: $nav-spacing-sides;
				}

				@include mq(desktop) {
					margin-left: $nav-spacing-ends--desktop;
				}

				@include mq(desktop-wide) {
					@include soft--sides(7%);
					margin-left: $nav-spacing-ends--desktop-wide;
				}

				&, &:hover {
					color: $c--white;
				}

				&:hover {
					background-color: mix($c--brand-secondary, $c--white, 90%);
				}
			}
		}
	}

	&__mobile {
		@include hardware;
		@include soft--sides($spacing-unit--x-small);
		@include soft--ends($spacing-unit--small);
		@include cf;
		position: fixed;
		top: $nav-height; right: 0; left: 0;
		z-index: z('mobile-site-nav');
		background: $c--white;
		transition: transform .3s $ease--in-out-cubic, visibility .3s linear;

		&--hidden {
			visibility: hidden;
			transform: translateY(-100%);

			nav {
				opacity: 0;
				transform: translateY(-100%);
			}
		}

		@include mq(phone) {
			@include soft--sides($spacing-unit--small);
		}

		@include mq(tablet-wide) {
			@include soft--sides($border--tablet-wide);
			top: $nav-height--tablet-wide;
		}

		@include mq(desktop) {
			@include soft--sides($border--desktop);
			top: $nav-height--desktop;
		}

		nav {
			opacity: 1;
			text-align: center;
			transition: opacity .5s .18s $ease--smooth-fade, transform .5s .18s $ease--out-expo;

			@include mq(phone-wide) {
				float: left;
				width: 50%;
			}
		}

		a {
			@extend .a--subtle;
			display: block;
			color: $c--brand-secondary;

			&.is-active {
				background: $c--brand-secondary;
				color: $c--white;
				text-decoration: none;
			}
		}
	}
}