/*---

Clickable buttons:

```
<div class="button-wrapper">
	<button class="button">Button</button>
</div>

<div class="button-wrapper">
	<a href="#0" class="button">Button (link)</a>
</div>
```

Alternate styles using `secondary`, `positive` and `negative` modifiers:

```
<div class="button-wrapper">
	<button class="button button--secondary">Button: Secondary</button>
</div>

<div class="button-wrapper">
	<button class="button button--positive">Button: Positive</button>
</div>

<div class="button-wrapper">
	<button class="button button--negative">Button: Negative</button>
</div>
```

And sized using `large` and `small`:

```
<div class="button-wrapper">
	<button class="button button--large">Button: Large</button>
</div>

<div class="button-wrapper">
	<button class="button button--small">Button: Small</button>
</div>
```

Other states:

```
<div class="button-wrapper">
	<button class="button" disabled>Button: Disabled</button>
</div>

<div class="button-wrapper">
	<button>Unstyled button</button>
</div>
```

*/

// basic buttons

.button {
	position: relative;
	padding: rem(12px);
	border: solid 5px $c--brand-primary;
	border-image: url('/assets/img/textures/border.png') 10 fill repeat;
	background-color: transparent;
	color: $c--brand-primary;
	font-family: $stack;
	font-size: $p--xx-small;
	font-weight: $w--bold;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: ls('large');

	@include mq(tablet) {
		padding: rem(13px) rem(20px);
		font-size: $p--x-small;
	}

	@include mq(tablet-wide) {
		padding: rem(17px) rem(30px);
		font-size: $p--small;
	}

	&:hover {
		background-color: $c--brand-primary;
		color: $c--white;
	}

	&:focus {
		[data-whatinput='keyboard'] & {
			box-shadow: 0px 0px 3px $c--blue, 0px 0px 6px $c--blue, 0px 0px 9px $c--blue;
		}
	}

	&:active {
		top: 4px;
	}

	&[disabled] {
		&,
		&:focus {
			background-color: $c--grey-light;
			color: mix($c--grey-light, $c--black, 60%);
		}
	}
}


// colours

.button--secondary {
	border-color: $c--brand-secondary;
	border-image: url('/assets/img/textures/border-secondary.png') 10 fill repeat;
	color: $c--brand-secondary;

	&:hover {
		background-color: $c--brand-secondary;
	}
}

.button--cream {
	border-color: $c--cream;
	border-image: url('/assets/img/textures/border-cream.png') 10 fill repeat;
	color: $c--cream;

	&:hover {
		background-color: $c--cream;
		color: $c--brand-secondary;
	}
}

.button--positive {
	background-color: $c--positive;
}

.button--negative {
	background-color: $c--negative;
}


// sizes

.button--large {
	padding: rem(22px) rem(30px);

	@include mq(tablet) {
		padding: rem(25px) rem(40px);
		font-size: $p--medium;
	}
}

.button--small {
	font-size: $p--small;
	padding: rem(12px);
}

.button--min-width {
	@include mq(phone) {
		min-width: rem(280px);
	}
}
