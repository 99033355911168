/*

	Basic, low level typography

*/

$typography-elems:
	'p',
	'ul',
	'ol';

$media-elems:
	'.blockquote',
	'.button-wrapper',
	'.media',
	'.table-wrapper';

// Headings

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;

	&[id] {
		position: relative;

		&::before {
			@include pseudo($pos: relative);
			display: block;
			width: 100%;
			height: $nav-height;
			margin-bottom: $spacing-unit;
			margin-top: n($nav-height);

			@include mq(tablet-wide) {
				height: $nav-height--tablet-wide;
				margin-top: n($nav-height--tablet-wide);
			}

			@include mq(desktop) {
				height: $nav-height--desktop;
				margin-top: n($nav-height--desktop);
			}
		}
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.heading {
	@include heading;
}

.h1 {
	font-size: $h--large;
	line-height: .85;

	@include mq(tablet-wide) {
		font-size: $h--xx-large;
	}

	@include mq(desktop-wide) {
		font-size: $h--xxx-large;
	}
}

.h2 {
	font-size: $h--large;

	@include mq(tablet-wide) {
		font-size: $h--x-large;
	}

	@include mq(desktop-wide) {
		font-size: $h--xx-large;
	}
}

.h3 {
	font-size: $h--medium;

	@include mq(tablet-wide) {
		font-size: $h--large;
	}

	@include mq(desktop-wide) {
		font-size: $h--x-large;
	}
}

.h4 {
	font-size: $h--small;

	@include mq(tablet-wide) {
		font-size: $h--medium;
	}

	@include mq(desktop-wide) {
		font-size: $h--large;
	}
}

.h5 {
	font-size: $h--x-small;

	@include mq(tablet-wide) {
		font-size: $h--small;
	}
}

.h6 {
	font-size: $h--xx-small;

	@include mq(tablet-wide) {
		font-size: $h--x-small;
	}
}


// Basic typography styling

#{$typography-elems},
#{$media-elems} {
	margin-top: $rel-spacing-unit;

	&:first-child {
		margin-top: 0;
	}
}

#{$media-elems} {
	margin-top: $rel-spacing-unit--large;

	&:first-child {
		margin-top: 0;
	}
}

ol,
ul {
	list-style: none;
}


// Stylistic elements

b,
strong {
	font-weight: $w--bold;
}

small {
	font-size: 80%;
}


// Text alignment
.align--center {
	text-align: center;
}

.align--right {
	text-align: right;
}

.color--primary {
	color: $c--brand-primary;
}