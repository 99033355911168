.site-footer {
	@include soft--ends;
	position: relative;
	z-index: z('site-footer');
	background: $c--cream;

	&__inner {
		padding-bottom: $spacing-unit--large;
		font-size: $p--x-small;

		@include mq($to: tablet-small) {
			text-align: center;
			font-size: $p--xx-small;
		}

		h1 {
			text-transform: uppercase;
			letter-spacing: ls('large');
		}

		li {
			line-height: 2.6;
		}

		a {
			@extend .a--subtle;
		}
	}

	&__logo {
		@include push--auto;
		display: block;
		max-width: rem(150px);

		@include mq($to: tablet-small) {
			width: 25vw;
			margin-bottom: $spacing-unit--small;
		}
	}

	@include mq(tablet-wide, $media-feature: height) {
		padding-bottom: $spacing-unit--large;
	}

	&__social-icons {
		font-size: rem(40px);

		a {
			display: inline-block;
			vertical-align: top;

			&:hover {
				color: $c--brand-secondary;
			}
		}

		svg {
			display: block;
		}
	}

	&__copyright {
		text-align: center;
		font-size: $p--x-small;
	}
}