.is-hidden {
	@include hidden;
}

.vh {
	@include vh;
}

.cf {
	@include cf;
}

.push {
	margin-top: $spacing-unit;

	&--small {
		margin-top: $spacing-unit--small;
	}

	&--large {
		margin-top: $spacing-unit--large;
	}

	&--x-large {
		margin-top: $spacing-unit--large;

		@include mq(tablet) {
			margin-top: $spacing-unit--x-large;
		}
	}

	&--xx-large {
		margin-top: $spacing-unit--x-large;

		@include mq(tablet) {
			margin-top: $spacing-unit--xx-large;
		}
	}
}

.soft {

	&--ends {
		@include soft--ends($spacing-unit--large);

		@include mq(desktop) {
			@include soft--ends($spacing-unit--x-large);
		}

		@include mq(desktop-wide) {
			@include soft--ends($spacing-unit--xx-large);
		}
	}
}

.bg {

	&--cream {
		background-color: $c--cream;
	}
}