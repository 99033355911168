.range-index {

	@include mq($to: tablet-wide) {
		.column:nth-child(7) {
			clear: both;
		}
	}

	@include mq(tablet-wide) {

		.width--tablet-12:not(.width--tablet-wide-8) + .width--tablet-wide-8 {
			clear: both;
		}
	}
}

.range-includes {

	ul {
		overflow: hidden;

		@include mq(tablet-small) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	li {
		@include soft--ends($spacing-unit--x-small);
		@include soft--sides($spacing-unit--x-small);
		box-shadow: 1px 1px $c--cream-dark;

		@include mq(tablet-small) {
			@include soft--ends($spacing-unit--small);
		}

		@include mq(tablet) {
			@include soft--ends;
		}
	}
}