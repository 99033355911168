.site-header {
	position: relative;
	z-index: z('site-header');
	text-align: center;

	// Flexbox to the rescue!!
	@include mq(tablet-small) {

		.row {
			display: flex;
			align-items: center;
		}
	}

	&__placeholder {
		@include responsive-ratio(625, 670, true);
		display: block;
		position: relative;

		@include mq($to: tablet-small) {
			@include push--auto;
			max-width: rem(400px) * .8;
		}

		img {
			position: absolute;
			top: 50%; left: 50%;
			width: 158.84%;
			max-width: none;
			transform: translate(-50%, -50%);
		}
	}

	&__content {
		@include mq(tablet-small) {
			padding-top: $spacing-unit;
		}

		h1 {
			color: $c--brand-primary;
		}

		p {
			@include push--auto;
			max-width: rem(600px);
		}
	}
}