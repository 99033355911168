/*

	Font face

	example:

	@include font-face('Clarendon', 'clarendon-webfont');
	@include font-face('Clarendon', 'clarendon-webfont', $w--bold, italic);

*/

@import url(//hello.myfonts.net/count/34584c);

@font-face {
	font-family: 'Microbrew';
	font-weight: bold;
	font-style: normal;
	src: url('../static/fonts/microbrew-one/34687C_0_0.eot');
	src: url('../static/fonts/microbrew-one/34687C_0_0.eot?#iefix') format('embedded-opentype'),
		 url('../static/fonts/microbrew-one/34687C_0_0.woff2') format('woff2'),
		 url('../static/fonts/microbrew-one/34687C_0_0.woff') format('woff'),
		 url('../static/fonts/microbrew-one/34687C_0_0.ttf') format('truetype');
}

// @mixin font-face($name, $filename, $weight: normal, $style: normal) {
// 	@font-face {
// 		font-family: $name;
// 		src: url('../static/fonts/#{$filename}.woff2') format('woff2'),
// 			 url('../static/fonts/#{$filename}.woff') format('woff');
// 		font-style: $style;
// 		font-weight: $weight;
// 	}
// }


/*

	Font families

*/

/*@mixin font-roboto($weight: false, $size: false, $lh: false, $colour: false) {
	font-family: 'Roboto', 'Comic Sans MS', Arial, sans-serif;

	@if $weight { font-weight: $weight; }
	@if $size { font-size: $size; }
	@if $lh { line-height: $lh; }
	@if $colour { color: $colour; }
}*/
