.recipe {

	&__img {
		position: relative;
		z-index: 2;

		@include mq(tablet-small) {
			padding-top: 15%;
		}

		img {
			@include push--auto;
			display: block;
		}
	}

	&__label {
		display: block;
		padding-bottom: 5%;

		@include mq($to: tablet-small) {
			text-align: center;
		}

		+ .recipe__img {
			padding-top: 0;
		}
	}

	&__cta {
		text-align: center;

		@include mq(tablet-small) {
			text-align: right;
		}
	}

	&__content {
		position: relative;
		z-index: 1;
		color: $c--cream;

		a {
			color: $c--cream;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		@include mq(tablet-small) {

			.recipe--odd & {
				padding-left: 0;
			}

			.recipe--even & {
				padding-right: 0;
			}
		}

		ul {
			font-size: $p--x-small;
		}

		&-wrapper {
			position: relative;
			z-index: 1;
		}
	}

	&__bg {
		position: absolute;
		bottom: 0;
		z-index: 0;

		@include mq($to: tablet-small) {
			top: -15%; right: 0; left: 0;
		}

		@include mq(tablet-small) {
			top: 0;

			.recipe--odd & {
				right: 0; left: -15%;
			}

			.recipe--even & {
				right: -15%; left: 0;
			}
		}
	}
}