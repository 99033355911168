/*---

Basic text field examples:

```
<form>
	<fieldset class="row row--compact flush-first--1">

		<div class="column">
			<label class="label" for="form-example-text">Text</label>

			<input type="text" name="form-example-text" id="form-example-text">
		</div>

		<div class="column">
			<label class="label" for="form-example-text-value">Text (with value)</label>

			<input type="text" name="form-example-text-value" id="form-example-text-value" value="A test value">
		</div>

		<div class="column">
			<label class="label" for="form-example-text-required">Text (required)</label>

			<input type="text" name="form-example-text-required" id="form-example-text-required" required>
		</div>

		<div class="column">
			<label class="label" for="form-example-text-placeholder">Text (with placeholder)</label>

			<input type="text" name="form-example-text-placeholder" id="form-example-text-placeholder" placeholder="A placeholder value">
		</div>

		<div class="column">
			<label class="label" for="form-example-text-disabled">Text (disabled)</label>

			<input type="text" name="form-example-text-disabled" id="form-example-text-disabled" disabled>
		</div>

		<div class="column">
			<label class="label" for="form-example-text-disabled-2">Text (failed validation)</label>

			<input type="text" name="form-example-text-disabled-2" id="form-example-text-disabled-2" class="is-invalid" required>

			<span class="error-text">Name Required</span>
		</div>

		<div class="column">
			<label class="label" for="form-example-text-disabled-3">Text (passed validation)</label>

			<input type="text" name="form-example-text-disabled-3" id="form-example-text-disabled-3" class="is-valid" required>
		</div>

		<div class="column">
			<label class="label" for="form-example-email">Email</label>

			<input type="email" name="form-example-email" id="form-example-email">
		</div>

		<div class="column">
			<label class="label" for="form-example-date">Date</label>

			<input type="date" name="form-example-date" id="form-example-date">
		</div>

		<div class="column">
			<label class="label" for="form-search">Search</label>

			<input type="search" name="form-search" id="form-search">
		</div>

		<div class="column">
			<label class="label" for="form-example-telephone">Telephone</label>

			<input type="tel" name="form-example-telephone" id="form-example-telephone">
		</div>

		<div class="column">
			<label class="label" for="form-example-number">Number</label>

			<input type="number" name="form-example-number" id="form-example-number">
		</div>

		<div class="column">
			<label class="label" for="form-example-textarea">Textarea</label>

			<textarea name="form-example-textarea" id="form-example-textarea" rows="10"></textarea>
		</div>

		<div class="column">
			<label class="label" for="form-example-select-unstyled">Select (unstyled)</label>

			<select name="form-example-select-unstyled" id="form-example-select-unstyled">
				<option>Option 1</option>
				<option>Option 2</option>
				<option>Option 3</option>
				<option>Option 4</option>
				<option>Option 5</option>
				<optgroup label="Optgroup">
					<option>Option A</option>
					<option>Option B</option>
				</optgroup>
			</select>
		</div>

		<div class="column">
			<label class="label" for="form-example-select-styled">Select (styled, with custom arrow)</label>

			<div class="select">
				<select name="form-example-select-styled" id="form-example-select-styled">
					<option>Option 1</option>
					<option>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea tempore sint adipisci maiores explicabo obcaecati ipsa ex magnam, nostrum, quasi dolor nihil ipsam quaerat vitae saepe alias tempora, veritatis similique!</option>
					<option>Option 3</option>
					<option>Option 4</option>
					<option>Option 5</option>
					<optgroup label="Optgroup">
						<option>Option A</option>
						<option>Option B</option>
					</optgroup>
				</select>
			</div>
		</div>

		<div class="column">
			<label class="label" for="form-example-select-multiple">Select (multiple)</label>

			<select name="form-example-select-multiple" id="form-example-select-multiple" multiple="multiple" size="5" class="valid">
				<option>Option 1</option>
				<option>Option 2</option>
				<option>Option 3</option>
				<option>Option 4</option>
				<option>Option 5</option>
				<option>Option 6</option>
				<option>Option 7</option>
				<option>Option 8</option>
				<option>Option 9</option>
				<option>Option 10</option>
			</select>
		</div>

		<div class="column">
			<input id="form-example-checkbox-1b" type="checkbox" class="checkbox" checked>
			<label for="form-example-checkbox-1b">Checkbox</label>
		</div>

		<div class="column">
			<input id="form-example-radio-1b" type="radio" class="radio" checked>
			<label for="form-example-radio-1b">Checkbox</label>
		</div>
	</fieldset>
</form>
```

*/

$input-border-width: 1px;
$input-line-height: 1.5;
$input-padding: rem(8px);
$input-padding--tablet: rem(12px);
$input-validation-icon: $p;
$input-height: ($input-padding * 2) + (rem($input-border-width) * 2) + ($p * $input-line-height);
$input-height--tablet: ($input-padding--tablet * 2) + (rem($input-border-width) * 2) + ($p * $input-line-height);

input,
textarea,
select {
	display: block;
	height: $input-height;
	padding: $input-padding;
	border: 1px solid $c--cream-dark;
	border-radius: 0;
	background: $c--white;
	color: $c--black;
	font: normal #{$p--x-small}/#{$input-line-height} $stack;

	@include mq(tablet) {
		height: $input-height--tablet;
		padding: $input-padding--tablet;
	}

	&:focus {
		border-color: $c--brand-secondary;

		[data-whatinput='keyboard'] & {
			border-color: $c--blue;
			box-shadow: 0 0 5px $c--blue;
		}
	}

	@include input-placeholder {
		color: $c--grey-light;
	}

	&.is-invalid {
		border-color: $c--negative;

		&:focus {
			border-color: $c--negative;

			[data-whatinput='keyboard'] & {
				box-shadow: 0 0 5px $c--negative;
			}
		}
	}
}

input,
textarea {
	&.is-valid,
	&.is-invalid {
		background-size: $input-validation-icon;
		background-repeat: no-repeat;
		background-position: right $input-validation-icon center;
		padding-right: $input-validation-icon * 3;
	}

	&.is-valid {
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%22415.582%22 height=%22415.582%22 viewBox=%220 0 415.582 415.582%22%3E%3Cpath style=%22fill:%2324B35D%22 d=%22M411.47 96.426l-46.32-46.32c-5.48-5.482-14.37-5.482-19.852 0l-192.95 192.952-82.066-82.064c-5.48-5.482-14.37-5.482-19.85 0l-46.32 46.32c-5.482 5.48-5.482 14.37 0 19.852l138.31 138.31a13.99 13.99 0 0 0 9.927 4.112c3.592 0 7.185-1.37 9.925-4.112l249.195-249.2a14.034 14.034 0 0 0 0-19.85z%22/%3E%3C/svg%3E');
	}

	&.is-invalid {
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2232%22 height=%2232%22 viewBox=%220 0 32 32%22%3E%3Cpath style=%22fill:%23F50023%22 d=%22M28.943 24l-8-8 8-8a.823.823 0 0 0 0-1.165l-3.78-3.778a.82.82 0 0 0-1.164 0l-8 8-7.998-8a.825.825 0 0 0-1.165 0l-3.78 3.778a.824.824 0 0 0 0 1.165l8 8-8 8a.823.823 0 0 0 0 1.165l3.78 3.778a.824.824 0 0 0 1.165 0l8-7.998 7.998 8a.823.823 0 0 0 1.165 0l3.78-3.78a.824.824 0 0 0 0-1.166z%22/%3E%3C/svg%3E');
	}
}

textarea {
	height: auto;
}


// checkboxes and radios
$checkbox-size: $spacing-unit--small;
$radio-inner-size: $checkbox-size / 2;

.checkbox,
.radio {
	@include vh;

	+ label {
		cursor: pointer;
		padding-left: $checkbox-size + $spacing-unit--x-small;
		position: relative;

		&::before {
			background: center center no-repeat;
			background-size: 80%;
			border: 2px solid $c--grey;
			content: '';
			display: block;
			width: $checkbox-size;
			height: $checkbox-size;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	&:focus {
		[data-whatinput='keyboard'] & {
			+ label::before {
				border-color: $c--blue;
				box-shadow: 0 0 3px 1px $c--blue;
			}
		}
	}

	&[disabled] {
		+ label {
			cursor: not-allowed;
			opacity: .4;
			text-decoration: none;
		}
	}

	// validation
	&.is-invalid {
		+ label::before {
			border-color: $c--negative;
		}
	}
}

.checkbox {
	&:checked {
		+ label::before {
			background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%22415.582%22 height=%22415.582%22 viewBox=%220 0 415.582 415.582%22 style=%22fill:%23444444;%22%3E%3Cpath d=%22M411.47 96.426l-46.32-46.32c-5.48-5.482-14.37-5.482-19.852 0l-192.95 192.952-82.066-82.064c-5.48-5.482-14.37-5.482-19.85 0l-46.32 46.32c-5.482 5.48-5.482 14.37 0 19.852l138.31 138.31a13.99 13.99 0 0 0 9.927 4.112c3.592 0 7.185-1.37 9.925-4.112l249.195-249.2a14.034 14.034 0 0 0 0-19.85z%22/%3E%3C/svg%3E');
		}
	}
}

.radio {
	+ label::before {
		border-radius: 50%;
	}

	&:checked {
		+ label::after {
			background: $c--grey-dark;
			border-radius: 50%;
			content: '';
			display: block;
			width: $radio-inner-size;
			height: $radio-inner-size;
			position: absolute;
			top: 50%; left: ($checkbox-size - $radio-inner-size) / 2;
			transform: translateY(-50%);
		}
	}
}


// select and multi select
select {
	// iOS Zoom Hack
	@media screen and (-webkit-min-device-pixel-ratio: 0) {
		font-size: $p !important;
	}
}

select[size],
select[multiple] {
	background-image: none;
	height: auto;
	padding: ($input-padding / 2) $input-padding;

	option {
		padding: ($input-padding / 2);
	}
}

.select {
	position: relative;
	display: block;
	height: $input-height;

	select {
		padding-right: $input-validation-icon * 3;
	}

	// arrow
	&::after {
		@include css-triangle($c--grey-dark, 'down', 8px);
		top: 50%; right: $input-validation-icon;
		margin-top: -4px;
		z-index: 3;
		pointer-events: none;
	}
}


// disabled form fields

input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled] {
	&,
	&:focus,
	&:hover,
	&:active {
		background-color: $c--grey-light;
		border-color: $c--grey-light;
		color: $c--white;
	}
}


// error labels for extra descriptive errors
.error-text {
	background-color: $c--negative;
	color: $c--white;
	display: inline-block;
	font-size: $p--x-small;
	line-height: $lh--small;
	padding: $spacing-unit--x-small;
	position: relative;
	margin-top: $spacing-unit--x-small;

	&::before {
		@include css-triangle($c--negative, up, 10px);
		bottom: 100%; left: 10%;
		transform: translateX(-50%);
	}

	&--success {
		@include vh;
	}
}